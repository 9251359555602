import $ from "jquery";

import objectFitImages from "object-fit-images";
import svg4everybody from "svg4everybody";
import "lazysizes";
import "picturefill";
import "@accessible360/accessible-slick";
import "@fancyapps/fancybox";
import uiHelpers from "./ui-helpers";

svg4everybody();
uiHelpers.init();
objectFitImages();

const spd = 300; // default speed

// Send scrolling events to Google Analytics http://scrolldepth.parsnip.io/
if (typeof $.scrollDepth === "function") {
  $.scrollDepth();
}

// Responsive Nav
let navShown = false;
$(".menu-toggle").on("click", function(e) {
  if (navShown === true) {
    $(".js-header-logo").removeClass("inactive");
    $("#menu-header-menu").slideUp(spd / 2);
    $(".menu-toggle i")
      .removeClass("fa-times")
      .addClass("fa-bars");
    navShown = false;
  } else {
    $(".js-header-logo").addClass("inactive");
    $("#menu-header-menu").slideDown(spd / 2);
    $(this)
      .find("i")
      .addClass("fa-times")
      .removeClass("fa-bars");
    navShown = true;
  }
  e.preventDefault();
});

// Sticky nav
$(window).scroll(() => {
  const scrollTop = $(window).scrollTop();
  const logoHeight = $(".header__logo").outerHeight();
  const scrollHeight = $("body > .hero").outerHeight() - 218;

  if (scrollTop > scrollHeight) {
    $(".js-header-logo").removeClass("header__logo--default");
    $(".js-header-logo").addClass("header__logo--scrolled");
  } else {
    $(".js-header-logo").removeClass("header__logo--scrolled");
    $(".js-header-logo").addClass("header__logo--default");
  }
});

// Show contact form
$(".contacts a.form, .menu-contact").on("click", e => {
  $(".contacts a.form").hide();
  $("#contact")
    .slideDown(spd)
    .addClass("active");
  e.preventDefault();
});

$(".menu-contact").on("click", () => {
  $("html, body").animate(
    {
      scrollTop: $("#contact").offset().top - 300
    },
    spd
  );
});

// Accessible Slick
$(".js-carousel").slick({
  arrows: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "80px"
      }
    }
  ],
  slidesToShow: 1
});

$(".js-slider").slick({
  arrows: true,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "80px"
      }
    }
  ],
  slidesToShow: 1
});
